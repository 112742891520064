import queryString from 'query-string';

import type { InfiniteLoaderData, PaginationParams } from '@/api/types/common.types';
import { Notification, NotificationListResponse } from '@/api/types/notification.types';

import { httpClient } from '@/libs/http-client';

// This config is written for new base API URL
const config = {
  baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_3,
};

export class NotificationApi {
  static fetchNotifications = async (params: PaginationParams) => {
    const queryParams = queryString.stringify(params);

    const { data }: NotificationListResponse = await httpClient.get(
      `/subscriber/notifications?${queryParams}`,
      config,
    );

    const transformedData: InfiniteLoaderData<Notification> = {
      currentPage: data.current_page,
      lastPage: data.last_page,
      total: data.total,
      list: data.data,
    };

    return transformedData;
  };

  static fetchNotification = async (notificationId: string) => {
    const { data } = await httpClient.get<Notification>(
      `/subscriber/notification/${notificationId}`,
      config,
    );
    return data;
  };

  static readAllNotifications = async () => {
    const { data } = await httpClient.get<any>('/subscriber/notification-read', config);

    return data;
  };
}
