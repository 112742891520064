import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import styles from '@/layout/DesktopMenu/NotificationToggler/NotificationsSingle.module.scss';

export const NotificationSingleSkeleton = () => {
  return (
    <SkeletonTheme baseColor="#19191b" highlightColor="#444" width={80}>
      <div className={styles.content}>
        <Skeleton width={'100%'} height={'200px'} className="mb-3" />
        <div className={styles.info}>
          <Skeleton width={120} className="mb-3" />
          <Skeleton width={345} count={4} className="mb-3" />
          <Skeleton width={345} count={4} className="mb-3" />
        </div>
      </div>
    </SkeletonTheme>
  );
};
