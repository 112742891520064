'use client';

import { JSX, useTransition } from 'react';
import { isMobile } from 'react-device-detect';

import { useLocale, useTranslations } from 'next-intl';

import EnSVG from '@/icons/EnSVG';
import KaaSVG from '@/icons/KaaSVG';
import RuSVG from '@/icons/RuSVG';
import UzSVG from '@/icons/UzSVG';
import { usePathname, useRouter } from '@/navigation';
import { SVGProps } from '@/types/common.types';

import { httpClient } from '@/libs/http-client';

import { LocalesEnum } from '@/enums/locales.enums';

import { Dropdown } from '@/components/Dropdown/Dropdown';

import styles from '@/layout/LanguageDropdown/LanguageDropdown.module.scss';

// =================================================================

const languageIcon: Record<LocalesEnum, (props: SVGProps) => JSX.Element> = {
  en: EnSVG,
  ru: RuSVG,
  uz: UzSVG,
  kaa: KaaSVG,
};

// =================================================================

const languageLabeles: Record<LocalesEnum, string> = {
  en: 'English',
  ru: 'Русский',
  uz: 'O`zbekcha',
  kaa: 'Qaraqalpaqsha',
};

// =================================================================

export const LanguageDropdown = () => {
  const defaultLocale = useLocale();

  const t = useTranslations('languages');

  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const pathname = usePathname();

  const handleSwitchLocale = (locale: LocalesEnum) => {
    startTransition(() => {
      httpClient.defaults.headers['Locale'] = locale;

      router.replace(pathname, { locale });
    });
  };

  const Icon = languageIcon[defaultLocale as LocalesEnum];

  return (
    <div className={styles.dropdown}>
      <Dropdown trigger="click" value={defaultLocale} position={'bottom-left'}>
        <Dropdown.Label title="Language-dropdown">
          <Icon className={styles.labelIcon} />
          {defaultLocale}
        </Dropdown.Label>
        <Dropdown.Menu className={styles.dropdownMenu}>
          {Object.keys(LocalesEnum).map(key => {
            const LocaleIcon = languageIcon[LocalesEnum[key as keyof typeof LocalesEnum]];
            const languageLabel = languageLabeles[LocalesEnum[key as keyof typeof LocalesEnum]];

            return (
              <Dropdown.Option
                as="li"
                key={key}
                className={styles.listItem}
                // value={LocalesEnum[key as keyof typeof LocalesEnum]}
                // className={styles.dropdownItem}
                // disabled={isPending}
                // icon={languageIcon[LocalesEnum[key as keyof typeof LocalesEnum]]}
              >
                <button
                  onClick={() => handleSwitchLocale(LocalesEnum[key as keyof typeof LocalesEnum])}
                  className={styles.dropdownItem}
                  disabled={isPending}
                >
                  <LocaleIcon width={24} height={24} />
                  {languageLabeles[LocalesEnum[key as keyof typeof LocalesEnum]]}
                </button>
              </Dropdown.Option>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
