const DoubleCheckSVG = () => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7332 0.573596C16.0441 0.827944 16.0899 1.28614 15.8356 1.59701L9.74563 9.04029C9.24089 9.6572 8.33365 9.75296 7.71123 9.25502L4.63656 6.79529C4.32292 6.54437 4.27207 6.0867 4.52298 5.77306C4.7739 5.45941 5.23157 5.40856 5.54521 5.65948L8.61988 8.11921L14.7098 0.675938C14.9642 0.365069 15.4224 0.319249 15.7332 0.573596Z"
        fill="currentColor"
      />
      <path
        d="M7.4104 5.67232L10.7447 1.59701C10.9991 1.28614 10.9533 0.827944 10.6424 0.573596C10.3315 0.319249 9.87333 0.365069 9.61898 0.675938L6.29294 4.7411L7.4104 5.67232Z"
        fill="currentColor"
      />
      <path
        d="M5.10762 8.48682L3.99016 7.55561L3.51502 8.13634L1.16368 6.37284C0.842349 6.13184 0.386494 6.19696 0.145497 6.51829C-0.0954996 6.83962 -0.0303773 7.29548 0.290952 7.53647L2.64229 9.29998C3.26584 9.76764 4.1472 9.66067 4.64077 9.05741L5.10762 8.48682Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DoubleCheckSVG;
