import Image from 'next/image';
import { useSearchParams } from 'next/navigation';

import { useNotificationsDetail } from '@/views/NotificationDetailView/useNotificationDetail';

import styles from '@/layout/DesktopMenu/NotificationToggler/NotificationsSingle.module.scss';
import { NotificationSingleSkeleton } from '@/layout/DesktopMenu/NotificationToggler/NotificationsSingleSkeleton';

export const NotificationSingle = () => {
  const searchParams = useSearchParams();
  const notificationId = searchParams.get('notification_id');
  const { data: notification, isPending } = useNotificationsDetail(notificationId as string);

  if (isPending) return <NotificationSingleSkeleton />;

  return (
    <div className={styles.content}>
      <div className={styles.imageContainer}>
        <Image
          fill
          src={notification?.image || ''}
          alt={notification?.title || ''}
          className={styles.cardImage}
        />
      </div>
      <div className={styles.info}>
        <h3 className={styles.title}>{notification?.title}</h3>
        <p className={styles.description}>{notification?.full_text}</p>
      </div>
    </div>
  );
};
