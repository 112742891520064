import { Fragment } from 'react';

import clsx from 'clsx';
import Image from 'next/image';

import { Link } from '@/navigation';

import type { Notification } from '@/api/types/notification.types';

import styles from '@/layout/DesktopMenu/NotificationToggler/NotificationCard.module.scss';

interface NotificationCardProps {
  notification: Notification;
}

export const NotificationCard = (props: NotificationCardProps) => {
  const { notification } = props;

  return (
    <Fragment>
      <div className={styles.date}>{notification.sent_date?.split(' ')[0]}</div>
      <Link
        href={`?notification_id=${notification.id}`}
        className={clsx(styles.notificationCard, { [styles.isRead]: !notification.is_read })}
      >
        <div className={styles.imageContainer}>
          <Image
            fill
            src="/assets/images/notification-card-placeholder.png"
            alt="movie-card-skelton"
            className={styles.skeltonImage}
          />

          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={notification.image} alt={notification.title} className={styles.cardImage} />
          <span className={styles.time}>{notification.sent_date?.split(' ')[1]}</span>
        </div>
        <h3 className={styles.title}>{notification.title}</h3>
        <p className={styles.description}>{notification.description}</p>
      </Link>
    </Fragment>
  );
};
