'use client';

import { useKey } from 'react-use';

import clsx from 'clsx';

import { ChildrenProps } from '@/types/common.types';

import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';

import { Focusable } from '@/components/Focusable';

import styles from '@/layout/MobileMenu/SidebarToggler/Sidebar.module.scss';

import { useStore } from '@/contexts/StoreContext';

interface SidebarProps extends ChildrenProps {
  position: 'left' | 'right';
  style: 'light' | 'dark';
  isOpen: boolean;
  onClose: () => void;
}

export const Sidebar = (props: SidebarProps) => {
  const { children, isOpen, onClose } = props;

  useLockBodyScroll({ isLocked: isOpen });
  useKey('Escape', onClose);

  return (
    <Focusable className={clsx(styles.sidebar, styles[props.position], styles[props.style])}>
      {children}
    </Focusable>
  );
};
