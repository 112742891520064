import { useLocale } from 'next-intl';

import { Locale } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useInfiniteLoader } from '@/hooks/useInfiniteLoader';

import { NotificationApi } from '@/api/domains/notification.api';
import { Notification } from '@/api/types/notification.types';

import { NotificationQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useNotifications = () => {
  const locale = useLocale() as Locale;
  const { isInitialCheckDone } = useVerification();

  const result = useInfiniteLoader<Notification, unknown>({
    queryKey: NotificationQueryKeys.list(locale),
    queryFn: ({ pageParam = 1 }) =>
      NotificationApi.fetchNotifications({ page: pageParam, limit: 5 }),
    initialPageParam: 1,
    enabled: isInitialCheckDone,
  });

  return result;
};
