import type { SVGProps } from '@/types/common.types';

const ArrowLeftSVG = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill="white" fill-opacity="0.1" />
      <path
        d="M7.33331 12H16.6666M7.33331 12L11.3333 16M7.33331 12L11.3333 8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeftSVG;
