import DoubleCheckSVG from '@/icons/DoubleCheckSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { NotificationApi } from '@/api/domains/notification.api';
import { UserApi } from '@/api/domains/user-api';

import styles from '@/layout/DesktopMenu/NotificationToggler/ReadAllButton.module.scss';

export const ReadAllButoon = () => {
  const { userCredentials, setUserCredentials } = useVerification();

  const onReadAll = async () => {
    await NotificationApi.readAllNotifications();
    const userData = await UserApi.getMe();
    await setUserCredentials(userData);
  };
  const hasUnReadNotifications = userCredentials?.notifications;

  return (
    <div className={styles.container}>
      <button className={styles.readAllButton} onClick={onReadAll}>
        <DoubleCheckSVG /> Прочитать все
      </button>
    </div>
  );
};
