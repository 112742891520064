'use client';

import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import * as yup from 'yup';

import { PromoCodeForm } from '@/app/actions/promo-code/types';
import PromoSVG from '@/icons/PromoSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { Button } from '@/components/Buttons';
import { Form } from '@/components/Form';

import { useNotification } from '@/contexts/NotificationContext';

import styles from '@/views/UserProfileViews/UserSubscriptionsView/PromoCodeView/PromoCode.module.scss';

interface PromoCodeFormViewProps {
  handleOnSuccessFn?: () => void;
  isModalVariant?: boolean;
}

// =================================================================

export const PromoCodeFormView = (props: PromoCodeFormViewProps) => {
  const { handleOnSuccessFn, isModalVariant = false } = props;
  const t = useTranslations();

  const { notification } = useNotification();

  const { checkAuthVerification, closeVerificationModal } = useVerification();

  const handleSubmitWithCheckAuth = checkAuthVerification<{ data: PromoCodeForm }>({
    onSuccess: async params => {
      closeVerificationModal();
      const { data } = params!;
      try {
        const { message } = await UserApi.addPromoCode(data.code);

        notification('success', message);
        if (typeof handleOnSuccessFn === 'function') handleOnSuccessFn();
      } catch (error) {
        if (!error.response) {
          throw error;
        }

        const { message } = error.response;
        notification('error', message);
      }
    },
  });

  const schema = useMemo(
    () =>
      yup
        .object({
          code: yup.string().trim().required(t('formWarningMassage.requiredField')),
        })
        .required(),
    [t],
  );

  const methods = useForm<PromoCodeForm>({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: PromoCodeForm) => {
    handleSubmitWithCheckAuth({ data });
  };

  return (
    <div className={styles.promoCode}>
      <FormProvider {...methods}>
        <Form
          isSubmitting={methods.formState.isSubmitting}
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.form}
        >
          <Form.Body>
            <Form.Input
              name="code"
              icon={PromoSVG}
              label={t('page.userProfile.placeholderPromoCode')}
              placeholder={t('page.userProfile.placeholderPromoCode')}
              errorClassName={styles.errorMessage}
              className={styles.input}
              autoFocus={!isMobile}
            />
          </Form.Body>
          <Form.Footer className={styles.formFooter}>
            <Button
              as="button"
              size="lg"
              variant="primary"
              type="submit"
              disabled={methods.formState.isSubmitting}
              className={clsx(styles.button, {
                [styles.full]: isModalVariant,
              })}
            >
              {t('page.userProfile.activate')}
            </Button>
          </Form.Footer>
        </Form>
      </FormProvider>
    </div>
  );
};
