import { useSearchParams } from 'next/navigation';

import ArrowLeftSVG from '@/icons/ArrowLeftSVG';
import CloseSVG from '@/icons/CloseSVG';

import { IconButton } from '@/components/Buttons';

import styles from '@/layout/DesktopMenu/NotificationToggler/Notifications.module.scss';
import { NotificationsList } from '@/layout/DesktopMenu/NotificationToggler/NotificationsList';
import { NotificationSingle } from '@/layout/DesktopMenu/NotificationToggler/NotificationsSingle';
import { ReadAllButoon } from '@/layout/DesktopMenu/NotificationToggler/ReadAllButton';

import { useStore } from '@/contexts/StoreContext';

export const Notifications = () => {
  const { hideNotification } = useStore(state => state);
  const searchParams = useSearchParams();

  const notificationId = searchParams.get('notification_id');

  const onBack = () => {
    const currentSearchParams = new URLSearchParams(Array.from(searchParams.entries()));

    currentSearchParams.delete('notification_id');

    window.history.replaceState(null, '', `?${currentSearchParams.toString()}`);
  };

  const onClose = () => {
    hideNotification();
    onBack();
  };

  return (
    <aside className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerLeft}>
          {notificationId && (
            <IconButton
              as="button"
              icon={ArrowLeftSVG}
              iconWidth={24}
              iconHeight={24}
              onClick={onBack}
              className={styles.closeButton}
            />
          )}
          <h2 className={styles.heding}>Уведомления</h2>
        </div>
        <IconButton
          as="button"
          icon={CloseSVG}
          iconWidth={16}
          iconHeight={16}
          onClick={onClose}
          className={styles.closeButton}
        />
      </header>
      <main>
        {!notificationId && <ReadAllButoon />}
        {notificationId ? <NotificationSingle /> : <NotificationsList />}
      </main>
    </aside>
  );
};
