import { useQuery } from '@tanstack/react-query';

import { useLocale } from 'next-intl';

import type { Locale } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { NotificationApi } from '@/api/domains/notification.api';

import { NotificationQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useNotificationsDetail = (notificationId: string) => {
  const locale = useLocale() as Locale;
  const { isInitialCheckDone } = useVerification();

  const { data, isPending, status } = useQuery({
    queryKey: NotificationQueryKeys.details(notificationId, locale),
    queryFn: () => NotificationApi.fetchNotification(notificationId),
    enabled: isInitialCheckDone,
  });

  return { data, isPending, status };
};
