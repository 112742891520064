const ActiveNotificationsSVG = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5 5C18.5 6.65685 17.1569 8 15.5 8C13.8431 8 12.5 6.65685 12.5 5C12.5 3.34315 13.8431 2 15.5 2C17.1569 2 18.5 3.34315 18.5 5Z"
        fill="#FF4D4D"
      />
      <path
        d="M10 18C11.1304 18 12.0956 17.3594 12.4767 16.4574C12.4926 16.4198 12.5 16.3794 12.5 16.3388C12.5 16.1517 12.342 16 12.1471 16H7.85291C7.658 16 7.5 16.1517 7.5 16.3388C7.5 16.3794 7.50736 16.4198 7.52325 16.4574C7.9044 17.3594 8.86957 18 10 18Z"
        fill="#555522"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.694 15H5.30604C3.82048 15 2.97249 13.3908 3.86383 12.2631C4.25842 11.7639 4.5016 11.1718 4.56704 10.5508L4.93038 7.10329C5.1263 5.2443 6.41497 3.71879 8.15567 3.07695V2.9862C8.15567 1.88925 8.9814 1 10 1C11.0186 1 11.8443 1.88925 11.8443 2.9862V3.07695C11.8607 3.08299 11.877 3.08911 11.8933 3.0953C11.4685 3.7386 11.2187 4.52527 11.2187 5.37479C11.2187 7.54941 12.8557 9.31229 14.8749 9.31229C15.0186 9.31229 15.1603 9.30336 15.2997 9.28601L15.433 10.5508C15.4984 11.1718 15.7416 11.7639 16.1362 12.2631C17.0275 13.3908 16.1795 15 14.694 15Z"
        fill="#FFFF4D"
      />
    </svg>
  );
};

export default ActiveNotificationsSVG;
