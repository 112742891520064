/* eslint-disable @next/next/no-img-element */
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Card, CardBody, CardSubtitle, CardTitle } from '@/components/Card';

import styles from '@/layout/DesktopMenu/NotificationToggler/NotificationCardSkelton.module.scss';

export const NotificationCardSkelton = () => {
  return (
    <Card className={styles.notificationCard}>
      <div className={styles.imageContainer}>
        <div className={styles.skeltonImage}>
          {/* @next/next/no-img-element */}
          <img src="/assets/images/notification-card-placeholder.png" alt="movie-card-skelton" />
        </div>
      </div>
      <CardBody className={styles.cardBody}>
        <div>
          <CardTitle>
            <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={320}>
              <Skeleton count={2} />
            </SkeletonTheme>
          </CardTitle>
          <CardSubtitle>
            <SkeletonTheme baseColor="#0F0F12" highlightColor="#444" width={190}>
              <Skeleton count={2} />
            </SkeletonTheme>
          </CardSubtitle>
        </div>

        <div className={styles.cardFooter}>
          <div className={styles.cardSubtitle}>
            <CardSubtitle>
              <SkeletonTheme baseColor="#0F0F12" highlightColor="#444" width={80}>
                <Skeleton />
              </SkeletonTheme>
            </CardSubtitle>
          </div>

          <CardTitle>
            <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={120}>
              <Skeleton />
            </SkeletonTheme>
          </CardTitle>
        </div>
      </CardBody>
    </Card>
  );
};
