import { useNotifications } from '@/layout/DesktopMenu/NotificationToggler/useNotifications';

import { LoaderButton } from '@/components/LoaderButton';

import { NotificationCard } from '@/layout/DesktopMenu/NotificationToggler/NotificationCard';
import { NotificationCardSkelton } from '@/layout/DesktopMenu/NotificationToggler/NotificationCardSkelton';

import styles from './NotificationsList.module.scss';

export const NotificationsList = () => {
  const { data, handleButtonClick, hasNextPage, isLoading, ref, status } = useNotifications();

  const isPending = status === 'pending';

  if (isPending && !data) {
    return (
      <ul style={{ display: 'grid', gap: '1rem', padding: '1rem' }}>
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <li key={index}>
              <NotificationCardSkelton />
            </li>
          ))}
      </ul>
    );
  }

  return (
    <ul className={styles.list}>
      {data?.list?.map(notification => (
        <li key={notification.id}>
          <NotificationCard notification={notification} />
        </li>
      ))}
      <li>
        {!isPending && hasNextPage && (
          <LoaderButton ref={ref} isLoading={isLoading} onClick={handleButtonClick} />
        )}
      </li>
    </ul>
  );
};
