'use client';

import { Fragment } from 'react';

import { Logo } from '@/components/Logo';

import { NotificationToggler } from '@/layout/DesktopMenu/NotificationToggler';
import styles from '@/layout/MobileMenu/MobileMenu.module.scss';
import { MobileNavigation } from '@/layout/MobileMenu/MobileNavigation';

export const MobileMenu = () => {
  return (
    <Fragment>
      <nav className={styles.mobileMenu}>
        {/* <SidebarToggler /> */}
        <Logo className={styles.mobileHeaderLogo} />
        <NotificationToggler />
      </nav>
      <MobileNavigation />
    </Fragment>
  );
};
