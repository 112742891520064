import { Fragment, useEffect } from 'react';

import { Notifications } from './Notifications';
import { useSearchParams } from 'next/navigation';

import ActiveNotificationsSVG from '@/icons/ActiveNotificationsSVG';
import NotificationsSVG from '@/icons/NotificationsSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { FadeTransition } from '@/components/Animations';
import { BaseTransition } from '@/components/Animations/BaseTransition';
import { Backdrop } from '@/components/Backdrop';
import { IconButton } from '@/components/Buttons';
import { Portal } from '@/components/Portal';

import styles from '@/layout/DesktopMenu/NotificationToggler/NotificationToggler.module.scss';
import { Sidebar } from '@/layout/MobileMenu/SidebarToggler/Sidebar';

import { useStore } from '@/contexts/StoreContext';

export const NotificationToggler = () => {
  const { userCredentials } = useVerification();
  const { hideNotification, isNotificationVisible, showNotification } = useStore(state => state);

  const searchParams = useSearchParams();
  const notificationId = searchParams.get('notification_id');

  useEffect(() => {
    if (notificationId) {
      showNotification();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationId]);

  const hasUnReadNotifications = userCredentials?.notifications || 0;

  return (
    <Fragment>
      <IconButton
        as="button"
        onClick={showNotification}
        icon={hasUnReadNotifications ? ActiveNotificationsSVG : NotificationsSVG}
        iconWidth={25}
        iconHeight={25}
        className={styles.notificationsIcon}
      />
      <Portal>
        <FadeTransition inProp={isNotificationVisible}>
          <Backdrop onClick={hideNotification} className={styles.backdropSidebar} />
        </FadeTransition>
        <BaseTransition
          inProp={isNotificationVisible}
          timeout={300}
          classNames={{
            enter: styles.slideEnter,
            enterActive: styles.slideEnterActive,
            exit: styles.slideExit,
            exitActive: styles.slideExitActive,
          }}
        >
          <Sidebar
            isOpen={isNotificationVisible}
            onClose={hideNotification}
            style="dark"
            position="right"
          >
            <Notifications />
          </Sidebar>
        </BaseTransition>
      </Portal>
    </Fragment>
  );
};
