import type { SVGProps } from '@/types/common.types';

const KaaSVG = (props: SVGProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_10654_30747)">
        <path
          d="M20 6H0V0.473702C0 0.212114 0.154414 0 0.344843 0H19.6552C19.8456 0 20 0.212114 20 0.473702L20 6Z"
          fill="#7198DD"
        />
        <path
          d="M19.6552 20H0.344844C0.154414 20 0 19.7525 0 19.4473V13H20V19.4473C20 19.7526 19.8456 20 19.6552 20Z"
          fill="#73AF00"
        />
        <path d="M20 6H0V12H20V6Z" fill="#D9A214" />
        <path d="M20 6H0V6.83913H20V6Z" fill="#FF4B55" />
        <path d="M20 12H0V13H20V12Z" fill="#FF4B55" />
        <path
          d="M8.7247 4.68803C7.86053 4.68803 7.16004 3.98922 7.16004 3.12816C7.16004 2.26394 7.86048 1.56197 8.7247 1.56197C8.72805 1.56197 8.7313 1.56197 8.73455 1.56202C8.78543 1.56268 8.80326 1.49512 8.75841 1.47107C8.4923 1.32842 8.18861 1.24771 7.86574 1.25005C6.83172 1.25741 5.99179 2.11427 6.00279 3.14829C6.0136 4.17165 6.85023 5 7.87927 5C8.19769 5 8.49713 4.91943 8.75994 4.77875C8.80465 4.75479 8.78658 4.68722 8.73589 4.68794C8.73206 4.68803 8.72838 4.68803 8.7247 4.68803Z"
          fill="#F5F5F5"
        />
        <path
          d="M11.6387 2.80459L11.5688 3.01413L11.3479 3.0158C11.3194 3.01599 11.3075 3.05252 11.3305 3.0695L11.5081 3.20071L11.4415 3.41131C11.4329 3.43856 11.4639 3.46113 11.4872 3.44449L11.6669 3.31605L11.8466 3.44449C11.8699 3.46113 11.9009 3.43856 11.8923 3.41131L11.8257 3.20071L12.0033 3.0695C12.0263 3.05252 12.0144 3.01599 11.9858 3.0158L11.765 3.01413L11.6951 2.80459C11.6861 2.77738 11.6477 2.77738 11.6387 2.80459Z"
          fill="#F5F5F5"
        />
        <path
          d="M13.1953 2.80459L13.1255 3.01413L12.9046 3.0158C12.876 3.01599 12.8641 3.05252 12.8871 3.0695L13.0648 3.20071L12.9982 3.41131C12.9896 3.43856 13.0206 3.46113 13.0439 3.44449L13.2236 3.31605L13.4033 3.44449C13.4266 3.46113 13.4576 3.43856 13.449 3.41131L13.3823 3.20071L13.56 3.0695C13.583 3.05252 13.5711 3.01599 13.5425 3.0158L13.3217 3.01413L13.2518 2.80459C13.2428 2.77738 13.2044 2.77738 13.1953 2.80459Z"
          fill="#F5F5F5"
        />
        <path
          d="M10.6362 4.25666L10.5664 4.4662L10.3455 4.46788C10.3169 4.46807 10.3051 4.5046 10.3281 4.52158L10.5057 4.65279L10.4391 4.86338C10.4305 4.89064 10.4615 4.91321 10.4848 4.89657L10.6645 4.76813L10.8442 4.89657C10.8675 4.91321 10.8985 4.89064 10.8899 4.86338L10.8233 4.65279L11.0009 4.52158C11.0239 4.5046 11.012 4.46807 10.9834 4.46788L10.7626 4.4662L10.6927 4.25666C10.6837 4.22955 10.6453 4.22955 10.6362 4.25666Z"
          fill="#F5F5F5"
        />
        <path
          d="M12.1924 4.25666L12.1225 4.4662L11.9017 4.46788C11.8731 4.46807 11.8612 4.5046 11.8842 4.52158L12.0619 4.65279L11.9952 4.86338C11.9866 4.89064 12.0176 4.91321 12.0409 4.89657L12.2206 4.76813L12.4003 4.89657C12.4236 4.91321 12.4546 4.89064 12.446 4.86338L12.3794 4.65279L12.557 4.52158C12.58 4.5046 12.5682 4.46807 12.5396 4.46788L12.3187 4.4662L12.2488 4.25666C12.2398 4.22955 12.2014 4.22955 12.1924 4.25666Z"
          fill="#F5F5F5"
        />
        <path
          d="M13.749 4.25666L13.6792 4.4662L13.4583 4.46788C13.4297 4.46807 13.4178 4.5046 13.4408 4.52158L13.6185 4.65279L13.5519 4.86338C13.5433 4.89064 13.5743 4.91321 13.5976 4.89657L13.7773 4.76813L13.957 4.89657C13.9803 4.91321 14.0113 4.89064 14.0027 4.86338L13.936 4.65279L14.1137 4.52158C14.1367 4.5046 14.1248 4.46807 14.0962 4.46788L13.8754 4.4662L13.8055 4.25666C13.7965 4.22955 13.7581 4.22955 13.749 4.25666Z"
          fill="#F5F5F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_10654_30747">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KaaSVG;
