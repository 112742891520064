import { memo } from 'react';

import cn from 'classnames';
import Image from 'next/image';

import CineramaLogoSVG from '@/icons/CineramaLogoSVG';
import { Link } from '@/navigation';
import { ClassNameProps } from '@/types/common.types';

import styles from '@/components/Logo/Logo.module.scss';

// =================================================================

type LogoProps = ClassNameProps & {
  isHomeLink?: boolean;
};

// =================================================================

export const Logo = memo((props: LogoProps) => {
  const { className, isHomeLink = true } = props;

  const classes = cn(styles.logoBase, className);
  const children = (
    <div className={styles.logoWrapper}>
      {process.env.NEXT_PUBLIC_CINERAMA_THEME_MODE === 'snow' && (
        <Image
          src={'/assets/images/snowman-hat.png'}
          alt="hat"
          className={styles.hatImage}
          width={45}
          height={40}
        />
      )}

      <CineramaLogoSVG className={styles.logoIcon} />
    </div>
  );

  if (isHomeLink) {
    return (
      <Link href="/" prefetch={false} className={classes}>
        {children}
      </Link>
    );
  }

  return <div className={classes}>{children}</div>;
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Logo.displayName = 'Logo';
}
